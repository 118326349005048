import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Apple from './App';
import Connect from "./connect";


export default function App() {
  return (
    <HashRouter>
      <Routes>
       
          <Route index element={<Connect />} />
          <Route path="connect" element={<Connect />} />
      </Routes>
    </HashRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);